var site = site || {};

(function ($, Drupal) {
  Drupal.behaviors.sitewideBannerV1 = {
    attach: function (context) {
      var siteBannerHeight;
      var isMobile = site?.client?.isMobile;
      var $headerBannerEle = $('.js-basic-carousel', '.js-site-header');
      var $headerBasicCarouselList = $('.js-basic-carousel__slide', $headerBannerEle);

      // disable for checkout
      if ($('body').hasClass('checkout')) {
        return;
      }

      // Basic tests to make sure we're not in private browsing like on iOS
      var privateBrowsing;

      try {
        sessionStorage.setItem('sessionTestVar', 1);
        sessionStorage.removeItem('sessionTestVar');
      } catch (err) {
        privateBrowsing = true;
      }
      if (privateBrowsing) {
        return;
      }

      // Starting point
      var $navBanner = $('.js-nav--banner', context);
      var $siteBanner = $('.js-site-banner--v1', context);
      var $metaNav = $('.site-header__main', context);
      var $contentWrapper = $('.js-site-content-wrapper', context);
      var $offer = $('.js-site-banner-offer', $siteBanner);
      var $basicCarousel = $('.js-basic-carousel', $navBanner);
      var $contentDim = $('.js-content-dim', context);
      var $close = $('.js-close-site-banner', $siteBanner);
      var $siteBannerCopy = $('.site-banner__copy', $siteBanner);
      var $basicCarouselFormatter = $basicCarousel.parent();
      var $offerActive;
      var $bannerActive;
      var $offerActiveCopy;
      var bannerActiveHeight;

      $siteBanner.removeClass('hidden');

      // Get height then hide again
      if(isMobile && $headerBannerEle.length && $headerBasicCarouselList.length) {
        siteBannerHeight = Math.max.apply(null, $headerBasicCarouselList.map(function () {
          return $(this).outerHeight(true);
        }).get());
      } else {
        siteBannerHeight = $siteBanner.outerHeight(true);
      }
      var animationTime = 400;

      $siteBanner.hide();

      function close() {
        $offer.removeClass('active');
        $('body').removeClass('offer-active');
        $metaNav.removeClass('site-header__main--inverted');
        if ($basicCarousel.hasClass('slick-initialized') && $offerActiveCopy && $offerActiveCopy.length) {
          $basicCarousel.slick('slickPlay');
          if ($offerActiveCopy !== undefined) {
            $offerActiveCopy.remove();
          }
          $offer = $('.js-site-banner-offer', $siteBanner);
        }
      }

      function setTopOfferContent(bannerActiveHeight, $offerActiveCopy) {
        $offerActiveCopy.css({
          top: bannerActiveHeight - 1
        });
      }

      $('.js-nav--banner', context).on('clickoutside', function () {
        close();
      });

      // Any link inside triggers slidedown content
      $('.site-banner__copy a, .js-close-offers', $siteBanner).on('click', function (event) {
        if (!$siteBannerCopy.hasClass('slideDown-disabled') && $basicCarouselFormatter.length) {
          event.preventDefault();
          $bannerActive = $(this).closest('.js-site-banner--v1');
          bannerActiveHeight = $bannerActive.outerHeight(true);
          $offerActive = $bannerActive.find('.js-site-banner-offer');
          $offerActiveCopy = $offerActive.clone(true);
          $offerActiveCopy.insertAfter($basicCarousel);
          $offerActiveCopy.toggleClass('active');
          setTopOfferContent(bannerActiveHeight, $offerActiveCopy);
          $offer = $('.js-site-banner-offer', $basicCarouselFormatter);
          // Show the faded background depending on if offer is open or not
          $('body').toggleClass('offer-active', $offerActiveCopy.hasClass('active'));
          $metaNav.toggleClass('site-header__main--inverted', $offerActiveCopy.hasClass('active'));
          if ($basicCarousel.hasClass('slick-initialized')) {
            $basicCarousel.slick('slickPause');
          }
          calculateLayout();
        }
      });

      $('.js-close-offers', $basicCarouselFormatter)
        .once()
        .on('click', function (event) {
          event.preventDefault();
          $offerActiveCopy = $(this).closest('.js-site-banner-offer');
          close();
        });

      // Should we show our banner?
      if (
        !sessionStorage.getItem('banner_viewed') &&
        $siteBanner.hasClass('site-banner__enabled') &&
        !$('html').hasClass('site-banner__is-open')
      ) {
        // Yes we should, animate it in
        $contentWrapper.animate(
          {
            'padding-top': siteBannerHeight
          },
          animationTime
        );
        $siteBanner.slideDown(animationTime, function () {
          $('html').addClass('site-banner__is-open');
        });
      }

      // If the banner is open on resize, adjust our page wrapper height
      $(window).on('resize', _.debounce(calculateLayout, 150));

      // Some basic math
      function calculateLayout() {
        if ($('html').hasClass('site-banner__is-open')) {
          // Get height then hide again
          if(isMobile && $headerBannerEle.length && $headerBasicCarouselList.length) {
            siteBannerHeight = Math.max.apply(null, $headerBasicCarouselList.map(function () {
              return $(this).outerHeight(true);
            }).get());
          } else {
            siteBannerHeight = $siteBanner.outerHeight(true);
          }
          $contentWrapper.css({
            'padding-top': siteBannerHeight
          });
        }
      }

      // Close the banner for the session
      $close.on('click', function () {
        sessionStorage.setItem('banner_viewed', 1);
        $contentWrapper.animate(
          {
            'padding-top': 0
          },
          animationTime
        );
        $siteBanner.slideUp(animationTime, function () {
          $('html').removeClass('site-banner__is-open');
          $(window).resize();
          $(document).trigger('siteBanner.close');
        });
        $('body').removeClass('offer-active');
      });

      // When the dark background is clicked, close our offer
      $contentDim.on('click', function () {
        close();
      });

      $contentDim.on('mouseover', function () {
        if ($basicCarousel.hasClass('slick-initialized')) {
          $basicCarousel.slick('slickPause');
        }
      });
    }
  };
})(jQuery, Drupal);
